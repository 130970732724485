<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group
      label="Type of Call"
      @click.native.stop
    >
      <v-select
        v-model="typeOfCallValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        placeholder="Select from list"
        :options="typeOfCallOptions"
        :clearable="false"
        :reduce="name => name._id"
        @input="setNatureOfCalls"
      />
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group
      label="Nature of Call"
      @click.native.stop
    >
      <v-select
        v-model="natureOfCallValue"
        label="title"
        placeholder="Select from list"
        :options="callNatures"
        :reduce="title => title.code"
        :clearable="false"
      />
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group
      label="Created Date"
    >
      <b-input-group>
        <flat-pickr
          v-model="createdAtValue"
          class="form-control"
          placeholder="Select Date Range"
          :config="flatPickrConfig"
        />
        <b-input-group-append is-text>
          <feather-icon
            icon="CalendarIcon"
            class="cursor-pointer"
            data-toggle
            size="18"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-button
      variant="outline-primary"
      size="sm"
      class="mr-1"
      @click="clearFilter"
    >
      Clear All
    </b-button>
    <b-button
      variant="primary"
      size="sm"
      @click="applyFilter"
    >
      Apply Filter
    </b-button>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BRow, BCol, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    flatPickr,
  },
  props: {
    typeOfCall: {
      type: [String, null],
      default: null,
    },
    natureOfCall: {
      type: [String, null],
      default: null,
    },
    createdAt: {
      type: [String, null],
      default: null,
    },
    typeOfCallOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      createdAtValue: this.createdAt,
      natureOfCallValue: this.natureOfCall,
      typeOfCallValue: this.typeOfCall,
      callNatures: [],
      flatPickrConfig: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, static: true, disableMobile: true,
      },
    }
  },
  watch: {
    createdAt: {
      immediate: true,
      handler(val) {
        this.createdAtValue = val
      },
    },
    natureOfCall: {
      immediate: true,
      handler(val) {
        this.natureOfCallValue = val
      },
    },
    typeOfCall: {
      immediate: true,
      handler(val) {
        this.typeOfCallValue = val
      },
    },
  },
  methods: {
    setNatureOfCalls() {
      this.callNatures = []

      const log = this.typeOfCallOptions.find(o => o._id === this.typeOfCallValue)
      if (log) {
        log.callNatures.forEach(element => {
          const obj = {}
          obj.title = element
          obj.code = element
          this.callNatures.push(obj)
        })

        // this.natureOfCall = this.callNatures[0].code ?? ''
      }
    },
    showAgainDropdown() {
      if (this.createdAtValue) {
        this.$emit('emitShowFilter')
      }
    },
    applyFilter() {
      this.$emit('update:createdAt', this.createdAtValue)
      this.$emit('update:natureOfCall', this.natureOfCallValue)
      this.$emit('update:typeOfCall', this.typeOfCallValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.createdAtValue = ''
      this.natureOfCallValue = ''
      this.typeOfCallValue = ''
      this.$emit('update:createdAt', this.createdAtValue)
      this.$emit('update:natureOfCall', this.natureOfCallValue)
      this.$emit('update:typeOfCall', this.typeOfCallValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
