import { ref, watch, computed } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useCallLogList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refCallLogsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'stringID',
      label: 'ID',
      sortable: true,
    },
    {
      key: 'customerName',
      label: 'Customer Name',
      sortable: true,
    },
    {
      key: 'customerContact',
      label: 'Customer contact',
      sortable: true,
      thStyle: {
        width: '11%',
      },
    },
    {
      key: 'typeOfCall.name',
      label: 'Type of call',
      sortable: true,
    },
    {
      key: 'natureOfCall',
      label: 'Nature of call',
      sortable: true,
    },
    {
      key: 'remarks',
      label: 'Details & Remarks',
      sortable: false,
      thStyle: {
        width: '20%',
      },
    },
    {
      key: 'createdAt',
      label: 'created date',
      sortable: true,
    },
    {
      key: 'dutyOfficer.name',
      label: 'created by',
      sortable: true,
    },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalCallLogs = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'createdAt')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const statusFilter = ref(route.query.status ?? null)
  const typeOfCallFilter = ref(route.query.typeOfCall ?? null)
  const natureOfCallFilter = ref(route.query.natureOfCall ?? null)
  const createdAtFilter = ref(route.query.createdAt || '')
  const toDeletedID = ref(null)
  const bookingFilter = ref(route.params.id || null)
  let initialLoad = true
  const currentDataLength = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refCallLogsTable.value ? refCallLogsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCallLogs.value,
    }
  })

  const refetchData = () => {
    refCallLogsTable.value.refresh()
  }

  watch([searchQuery, statusFilter, typeOfCallFilter, natureOfCallFilter, createdAtFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchCallLogs = (ctx, callback) => {
    store
      .dispatch('app-call-logs/fetchCallLogs', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        typeOfCall: typeOfCallFilter.value,
        natureOfCall: natureOfCallFilter.value,
        createdAt: createdAtFilter.value,
        bookingFilter: bookingFilter.value,
      },
      { root: true })
      .then(response => {
        const { callLogs, total } = response.data
        currentDataLength.value = callLogs.length
        callback(callLogs)
        totalCallLogs.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              status: statusFilter.value,
              typeOfCall: typeOfCallFilter.value,
              natureOfCall: natureOfCallFilter.value,
              createdAt: createdAtFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteCallLog = () => {
    store
      .dispatch('app-call-logs/deleteCallLog', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchCallLogs,
    tableColumns,
    perPage,
    currentPage,
    totalCallLogs,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCallLogsTable,

    refetchData,

    toDeletedID,
    deleteCallLog,
    currentDataLength,

    // Extra Filters
    statusFilter,
    typeOfCallFilter,
    natureOfCallFilter,
    createdAtFilter,
  }
}
