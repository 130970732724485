<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="8"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Call Logs
            </h1>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-right mb-1"
        md="3"
        cols="4"
      >
        <b-button
          variant="success"
          :to="{ name: 'appointment-call-logs-create' }"
          class="mobile_create_button"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle d-md-block d-none">Create New Call Log</span>
          <span class="align-middle d-md-none d-block">New</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-call-log-form"
                ref="filter_dropdown_call_log"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <call-log-dropdown-filters
                  :type-of-call.sync="typeOfCallFilter"
                  :nature-of-call.sync="natureOfCallFilter"
                  :created-at.sync="createdAtFilter"
                  :type-of-call-options="typeOfCallOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="typeOfCallFilter || natureOfCallFilter || createdAtFilter">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-if="typeOfCallFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="typeOfCallFilter = null"
                  >
                    <span class="text-capitalize">{{ resolveTypeOfCallName(typeOfCallFilter) }}</span>
                  </b-form-tag>
                </li>

                <li
                  v-if="natureOfCallFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="natureOfCallFilter = null"
                  >
                    <span class="text-capitalize">{{ natureOfCallFilter }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-if="createdAtFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="createdAtFilter = ''"
                  >
                    {{ createdAtFilter }}
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refCallLogsTable"
        class="position-relative min-height-150"
        :items="fetchCallLogs"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(stringID)="data">
          <div class="">
            <b-button
              v-if="canViewThisAction('show', 'CallLog')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="weight-600 align-middle detail-view-id"
              :to="{ name: 'appointment-call-logs-show', params: { id: data.item._id } }"
            >
              {{ data.item.stringID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id not-button"
            >
              {{ data.item.stringID }}
            </b-button>
          </div>
        </template>

        <template #cell(customerContact)="data">
          <div class="">
            <span class="">(65) {{ data.item.customerContact }}</span>
          </div>
        </template>

        <template #cell(remarks)="data">
          <div class="">
            <span v-if="data.item.remarks.length > 85">{{ data.item.remarks.substr(0, 100) + '...' }}</span>
            <span v-else>{{ data.item.remarks || '-' }}</span>
          </div>
        </template>

        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('show', 'CallLog') || canViewThisAction('update', 'CallLog') || canViewThisAction('delete', 'CallLog')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('show', 'CallLog')"
              :to="{ name: 'appointment-call-logs-show', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View Call Log</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'CallLog')"
              :to="{ name: 'appointment-call-logs-edit', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit Call Log</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'CallLog')"
              @click="deleteEntity(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete Call Log</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalCallLogs > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCallLogs"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BFormInput, BTable, BDropdown, BDropdownItem, BPagination, BInputGroup, BInputGroupAppend, BFormTag,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import store from '@/store'
import useCallLogList from './useCallLogList'
import callLogStoreModule from './callLogStoreModule'
import CallLogDropdownFilters from './CallLogDropdownFilters.vue'

export default {
  components: {
    // UserListAddNew,
    BFormTag,
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupAppend,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    CallLogDropdownFilters,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,
      typeOfCallOptions: [],
    }
  },
  beforeMount() {
    this.$http.get('appointment/call-log-types/respond-with/name-and-id')
      .then(response => {
        this.typeOfCallOptions = response.data.callLogTypes ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_call_log.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_call_log.show(true)
    },
    clearAllFilters() {
      this.typeOfCallFilter = null
      this.natureOfCallFilter = null
      this.createdAtFilter = ''
    },
    resolveTypeOfCallName(id) {
      const type = this.typeOfCallOptions.find(o => o._id === id)
      if (type) {
        return type.name || ''
      }
      return ''
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Selecting Delete will <strong>permanently delete</strong> this item. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete!',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.toDeletedID = id
            this.deleteCallLog()
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const CALL_LOG_APP_STORE_MODULE_NAME = 'app-call-logs'

    // Register module
    if (!store.hasModule(CALL_LOG_APP_STORE_MODULE_NAME)) store.registerModule(CALL_LOG_APP_STORE_MODULE_NAME, callLogStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALL_LOG_APP_STORE_MODULE_NAME)) store.unregisterModule(CALL_LOG_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const { canViewThisAction } = useAclUtils()

    const {
      fetchCallLogs,
      tableColumns,
      perPage,
      currentPage,
      totalCallLogs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCallLogsTable,
      refetchData,

      toDeletedID,
      deleteCallLog,
      currentDataLength,

      // UI
      resolveCallLogStatusVariant,

      // Extra Filters
      statusFilter,
      typeOfCallFilter,
      natureOfCallFilter,
      createdAtFilter,
    } = useCallLogList()

    return {

      // Sidebar

      fetchCallLogs,
      tableColumns,
      perPage,
      currentPage,
      totalCallLogs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCallLogsTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteCallLog,

      // Filter
      avatarText,

      canViewThisAction,

      // UI
      resolveCallLogStatusVariant,

      statusOptions,
      currentDataLength,

      // Extra Filters
      statusFilter,
      typeOfCallFilter,
      natureOfCallFilter,
      createdAtFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
